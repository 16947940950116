import * as Styled from "./OxConsultation.styled";

import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";

import { OxButton } from "src/components/OxButton";
import { OxLink } from "src/components/OxLink";
import React from "react";

export enum EOxConsultationVariant {
  Normal = "NORMAL",
  Alternative = "Alternative"
}

type TProps = {
  label: string;
  title: string;
  linkUrl: string;
  paragraph: string[];
  buttonTitle: string;
  fluidImages: any[];
  variant?: EOxConsultationVariant;
};

export const OxConsultation = ({
  label,
  title,
  linkUrl,
  paragraph,
  buttonTitle,
  fluidImages,
  variant,
  ...props
}: SCProps<"div", TProps>): JSX.Element => {
  const themeVariant = variant || EOxConsultationVariant.Normal;

  const getInputTheme = (): EInputTheme => {
    switch (themeVariant) {
      case EOxConsultationVariant.Normal:
        return EInputTheme.White;
      case EOxConsultationVariant.Alternative:
        return EInputTheme.BackgroundGold;
    }
  };

  return (
    <Styled.Container {...props}>
      <Styled.Asset fluidImages={fluidImages} />
      <Styled.Content variant={themeVariant}>
        <Styled.Label>{label}</Styled.Label>
        <Styled.Heading>{title}</Styled.Heading>
        {Array.isArray(paragraph) && paragraph.map((line: string, i: number) => {
          return <Styled.Paragraph key={i}>{line}</Styled.Paragraph>;
        })}
        <OxThemedInput theme={getInputTheme()}>
          <OxLink to={linkUrl}>
            <OxButton>{buttonTitle}</OxButton>
          </OxLink>
        </OxThemedInput>
      </Styled.Content>
    </Styled.Container>
  );
};
