import { EFontWeight, EFonts, EColors } from "src/config/enums";
import { fluidSizing } from "src/utils";
import styled, { css } from "styled-components";

import { EOxArcheFeatVariant } from "./OxArcheFeat";
import { OxContainer } from "src/components/OxContainer";
import { createTextStyle } from "src/utils/createTextStyle";
import { OxIcon } from "src/components/OxIcon";

export const Container = styled(OxContainer)<{ variant: EOxArcheFeatVariant }>(
  ({ theme, variant }) => {
    const commonStyles = css`
      display: flex;
    `;

    let variantStyles;
    switch (variant) {
      case EOxArcheFeatVariant.Default:
        variantStyles = css`
          ${theme.breakpoints.only("xs")} {
            flex-direction: column;
          }
          ${theme.breakpoints.up("s")} {
            flex-direction: row-reverse;
          }
        `;
        break;
      case EOxArcheFeatVariant.Reverse:
        variantStyles = css`
          ${theme.breakpoints.only("xs")} {
            flex-direction: column;
          }
          ${theme.breakpoints.up("s")} {
            flex-direction: row;
          }
        `;
        break;
    }
    return css`
      ${commonStyles};
      ${variantStyles};
    `;
  }
);

export const ContentWrapper = styled.div<{
  variant: EOxArcheFeatVariant;
}>(({ theme }) => {
  return css`
    width: 100%;
    ${fluidSizing([
      { prop: "padding-left", values: [0, 35, 60] },
      { prop: "padding-right", values: [0, 35, 60] },
      { prop: "padding-bottom", values: [30, 50, 50] },
      { prop: "padding-top", values: [20, 50, 50] }
    ])}

    ${theme.breakpoints.only("xs")} {
      padding-left: 0;
      padding-right: 0;
    }

    ${theme.breakpoints.up("s")} {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `;
});

export const TitleAsHero = styled.h3<{ variant: EOxArcheFeatVariant }>(
  ({ theme }) => css`
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Medium,
      [20, 28, 30],
      [28, 46, 46]
    )}
    align-items: center;
    background: ${EColors.SpringWood};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    ${fluidSizing([{ prop: "min-height", values: [200, 200, 200] }])}

    ${theme.breakpoints.only("xs")} {
      width: 100%;
    }
    ${theme.breakpoints.up("s")} {
      width: 50%;
    }
  `
);

export const TitleAsHeroIcon = styled(OxIcon)(
  () => css`
    ${fluidSizing([{ prop: "margin-bottom", values: [10, 10, 10] }])}
  `
);

export const Description = styled.p(
  () => css`
    ${createTextStyle(
      EFonts.Montserrat,
      EFontWeight.Light,
      [12, 12, 13],
      [18, 18, 20]
    )}
    ${fluidSizing([{ prop: "margin-bottom", values: [12, 12, 12] }])}
  `
);
