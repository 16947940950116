import { EFontWeight, EFonts } from "src/config/enums";
import { fluidSizing, toVw } from "src/utils";
import styled, { css } from "styled-components";

import { EOxConsultationVariant } from "./OxConsultation";
import { OxContainer } from "src/components/OxContainer";
import { OxImage } from "src/components/OxImage";
import { createTextStyle } from "src/utils/createTextStyle";

export const Container = styled(OxContainer)(
  ({ theme }) => css`
    display: flex;
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
  `
);

export const Asset = styled(OxImage)(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    ${theme.breakpoints.only("xs")} {
      height: ${toVw(230, "xs")};
    }
  `
);

export const Content = styled.div<{ variant: EOxConsultationVariant }>(
  ({ theme, variant }) => css`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${variant === EOxConsultationVariant.Normal &&
      css`
        background: ${theme.colors.secondary.variant};
        color: ${theme.colors.basic.white};
      `}
    ${variant === EOxConsultationVariant.Alternative &&
      css`
        background: ${theme.colors.secondary.main};
        color: ${theme.colors.primary.main};
      `}
    ${fluidSizing([
      { prop: "padding-top", values: [24, 48, 50] },
      { prop: "padding-bottom", values: [24, 48, 50] }
    ])}
  `
);

export const Label = styled.span`
  text-transform: uppercase;
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [10, 10, 10],
    [14, 14, 14]
  )}
`;

export const Heading = styled.h3`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Medium,
    [20, 28, 30],
    [28, 46, 46]
  )}
  text-transform: uppercase;
  text-align: center;
  ${fluidSizing([
    { prop: "padding-bottom", values: [10, 10, 14] },
    { prop: "padding-top", values: [10, 10, 14] },
    { prop: "padding-left", values: [50, 45, 85] },
    { prop: "padding-right", values: [50, 45, 85] },
    { prop: "line-height", values: [28, 33, 38], important: true }
  ])}
`;

export const Paragraph = styled.p(
  ({ theme }) => css`
    text-align: center;
    font-weight: ${theme.fontWeights.medium} !important;
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Light,
      [14, 14, 16],
      [18, 18, 22]
    )}
    ${fluidSizing([
      { prop: "padding-bottom", values: [10, 10, 14] },
      { prop: "padding-left", values: [28, 18, 45] },
      { prop: "padding-right", values: [28, 18, 45] }
    ])}
  `
);
