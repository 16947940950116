import * as Styled from "src/templates/personality-archetype/personality-archetype.styled";

import { EOxArcheFeatVariant, OxArcheFeat } from "src/components/OxArcheFeat";
import { EOxHeroVariant, OxHero } from "src/components/OxHero";
import { OxPageHelmet } from "src/components/OxPageHelmet";
import { OxConsultation } from "src/components/OxConsultation";
import React from "react";
import { ERoutes } from "src/config/enums";
import { OxComponentContainer } from "src/components/OxComponentContainer";
import { OxBackToTop } from "src/components/OxBackToTop";

import { getGatsbyImageDataFromImage } from "src/services/cms/dataConverter";

type TProps = {
  pageContext: any;
};

const PersonalitiesPage = ({
  pageContext: {
    personality,
    consultationTabletImage,
    consultationMobileImage,
  },
}: TProps): JSX.Element => {
  const consultationImages = [
    {
      ...getGatsbyImageDataFromImage(consultationMobileImage),
      media: `(max-width: 767px)`,
    },
    {
      ...getGatsbyImageDataFromImage(consultationTabletImage),
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <>
      <OxPageHelmet
        title={personality.seoTitle ? personality.seoTitle : personality.name}
        description={
          personality.seoDescription
            ? personality.seoDescription
            : personality.whoYouAre
        }
        keywords={personality.seoKeywords}
        image={personality.stoneImage.asset.gatsbyImage}
      />
      <OxHero
        title={[["You Are:"], [`“${personality.name}”`]]}
        variant={EOxHeroVariant.Half}
        fluidImages={[getGatsbyImageDataFromImage(personality.stoneImage)]}
      />
      <Styled.Quote>“{personality.quote}”</Styled.Quote>
      <OxArcheFeat
        titleAsHero={[["Your Stone:"], [personality.stone]]}
        description={personality.stoneDescription}
        variant={EOxArcheFeatVariant.Reverse}
      />
      <OxArcheFeat
        titleAsHero={[["Who"], ["You Are"]]}
        description={personality.whoYouAre}
        variant={EOxArcheFeatVariant.Default}
      />
      <OxArcheFeat
        titleAsHero={[["Your Beauty"], ["Profile"]]}
        description={personality.beautyProfile}
        variant={EOxArcheFeatVariant.Reverse}
      />
      <OxArcheFeat
        titleAsHero={[["Ouronyx"], ["Suggests"]]}
        description={personality.suggest}
        variant={EOxArcheFeatVariant.Default}
      />
      <OxComponentContainer
        marginTop={[45, 45, 45]}
        marginBottom={[78, 78, 78]}
      >
        <OxConsultation
          label="Consultation"
          title="START YOUR JOURNEY"
          linkUrl={ERoutes.Appointment}
          paragraph={[
            "Step into your beauty power.",
            "Book a consultation for a truly transformative experience.",
          ]}
          buttonTitle="BOOK"
          fluidImages={consultationImages}
        />
      </OxComponentContainer>
      <OxBackToTop />
    </>
  );
};

export default PersonalitiesPage;

export const Query = `
    query {
      personalities: allSanityArchetype(
        sort: { fields: [simpleColour], order: ASC }
        filter: { slug: { current: { ne: null } } }
      ) {
        nodes {
          name
          simpleColour
          quote
          stoneImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          stone
          stoneDescription
          whoYouAre
          beautyProfile
          suggest
          seoTitle
          seoKeywords
          seoDescription
          slug {
            current
          }
        }
      }
      consultationTabletImage: file(
        relativePath: { eq: "personality-archetype/BookingBanner-Tablet.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      consultationMobileImage: file(
        relativePath: { eq: "personality-archetype/BookingBanner-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
`;
