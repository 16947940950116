import * as Styled from "./OxHero.styled";

import React, { useContext, useEffect, useState } from "react";

import { LayoutContext } from "src/context/LayoutContext";
import { Link } from "gatsby";
import { OxIcon } from "src/components/OxIcon";
import { TOxCarouselItem } from "src/components/OxCarousel";

export enum EOxHeroVariant {
  Half = "HALF",
  HalfReversed = "HALF_REVERSED",
  OneThird = "ONE_THIRD",
}

type TProps = {
  title: string[][];
  description?: string[][];
  label?: string[][];
  labelLink?: string;
  fluidImages?: any[];
  carouselImages?: TOxCarouselItem[];
  image?: string;
  variant: EOxHeroVariant;
  eagerLoad?: boolean;
};

export const OxHero = (props: TProps): JSX.Element => {
  const [componentIsReady, setComponentIsReady] = useState(false);
  const layoutContext = useContext(LayoutContext);

  useEffect(() => {
    setTimeout(() => {
      setComponentIsReady(true);
    }, 1000);
  }, []);

  return (
    <Styled.Container variant={props.variant}>
      <Styled.Content>
        <div>
          {props.label && props.labelLink && (
            <Link to={props.labelLink}>
              <Styled.WaveTextLabel
                text={props.label}
                animationInit={
                  !layoutContext.websiteLoaderActive && componentIsReady
                }
              />
            </Link>
          )}
          {props.label && !props.labelLink && (
            <Styled.WaveTextLabel
              text={props.label}
              animationInit={
                !layoutContext.websiteLoaderActive && componentIsReady
              }
            />
          )}
          <Styled.WaveText
            text={props.title}
            animationInit={
              !layoutContext.websiteLoaderActive && componentIsReady
            }
          />
          {props.description && (
            <Styled.Paragraph
              text={props.description}
              animationInit={
                !layoutContext.websiteLoaderActive && componentIsReady
              }
            />
          )}
        </div>
        <OxIcon name="arrow-2" />
      </Styled.Content>
      {(props.fluidImages || props.image) && !props.carouselImages && (
        <Styled.Asset
          asBackground
          fluidImages={props.fluidImages}
          image={props.image}
          variant={props.variant}
          eagerLoad={props.eagerLoad}
        />
      )}
      {props.carouselImages && !props.fluidImages && (
        <Styled.Carousel
          items={props.carouselImages}
          transitionEffect="fade"
          controls={false}
          variant={props.variant}
          carouselOptions={{
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            swipe: false,
            draggable: false,
            pauseOnHover: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        />
      )}
    </Styled.Container>
  );
};
