import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { EOxHeroVariant } from "./OxHero";
import { OxCarousel } from "src/components/OxCarousel";
import { OxContainer } from "src/components/OxContainer";
import { OxImage } from "src/components/OxImage";
import { OxWaveText } from "src/components/OxWaveText";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled(OxContainer)<{ variant: EOxHeroVariant }>(
  ({ theme, variant }) => css`
    display: flex;
    align-items: center;
    ${theme.breakpoints.only("xs")} {
      padding-left: 0;
      padding-right: 0;
      ${variant === EOxHeroVariant.Half &&
        css`
          flex-direction: column;
        `}
      ${(variant === EOxHeroVariant.OneThird ||
        variant === EOxHeroVariant.HalfReversed) &&
        css`
          flex-direction: column-reverse;
        `}
    }
  `
);

export const WaveTextLabel = styled(OxWaveText)`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Bold,
    [10, 10, 10],
    [14, 14, 14]
  )}
`;

export const WaveText = styled(OxWaveText)`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Medium,
    [30, 35, 40],
    [35, 38, 46]
  )}
`;

export const Content = styled.div(
  ({ theme }) => css`
    width: 100%;
    & > div > a {
      color: ${theme.colors.primary.main};
    }
    & span {
      color: ${theme.colors.primary.main};
    }
    svg {
      color: ${theme.colors.primary.main};
      ${fluidSizing([
        { prop: "height", values: [13, 13, 13] },
        { prop: "margin-top", values: [22, 22, 22] },
        { prop: "margin-bottom", values: [14, 20, 20] },
        { prop: "margin-left", values: [-10, -10, -10] }
      ])}
      transform: rotate(90deg);
    }
    ${fluidSizing([
      { prop: "padding-top", values: [28, 28, 28] },
      { prop: "padding-bottom", values: [28, 28, 28] },
      { prop: "padding-left", values: [28, 40, 65] },
      { prop: "padding-right", values: [40, 50, 65] }
    ])}
    ${WaveText} {
      & > *:first-child {
        ${fluidSizing([{ prop: "margin-top", values: [8, 8, 8] }])}
      }
      & > *:last-child {
        ${fluidSizing([{ prop: "margin-bottom", values: [4, 4, 4] }])}
      }
    }
    ${theme.breakpoints.only("xs")} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    ${theme.breakpoints.up("s")} {
      flex: 1;
    }
  `
);

export const Paragraph = styled(OxWaveText)`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [13, 13, 13],
    [20, 20, 20]
  )}
`;

export const Asset = styled(OxImage)<{ variant: EOxHeroVariant }>(
  ({ theme, variant }) => css`
    width: 100%;
    ${fluidSizing([{ prop: "height", values: [290, 320, 400] }])};
    ${theme.breakpoints.up("s")} {
      ${(variant === EOxHeroVariant.Half ||
        variant === EOxHeroVariant.HalfReversed) &&
        css`
          width: 50%;
        `}
      ${variant === EOxHeroVariant.OneThird &&
        css`
          ${fluidSizing([{ prop: "width", values: [null, 400, 550] }])}
        `}
    }
  `
);

export const Carousel = styled(OxCarousel)<{ variant: EOxHeroVariant }>(
  ({ theme, variant }) => css`
    width: 100%;
    ${fluidSizing([{ prop: "height", values: [290, 320, 400] }])};
    ${theme.breakpoints.up("s")} {
      ${(variant === EOxHeroVariant.Half ||
        variant === EOxHeroVariant.HalfReversed) &&
        css`
          width: 50%;
        `}
      ${variant === EOxHeroVariant.OneThird &&
        css`
          ${fluidSizing([{ prop: "width", values: [null, 400, 550] }])}
        `}
    }
  `
);
